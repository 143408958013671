import axios from "axios";
import FormData from "form-data";

export const login = (user) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/auth/login", user, {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      remove_session();
      add_session(response.data.token);
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getUser = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/auth", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      set_user(response.data);
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const getUserRole = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/users/getAdminRoleWithPermissions", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      set_user_role(response.data);
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updatePassword = (password) => {
  return axios
    .post(
      "/api/settings/update-password",
      { ...password, token: is_logged_in() },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getDashboardStats = (duration) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/homeScreen/getDashboardStats/" + duration + "?v=2", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const getMembershipSummaryStats = (duration) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/homeScreen/getMemberSummary/" + duration, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const getAppSummaryStats = (duration) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/homeScreen/getAppSummary/" + duration, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const getRevenueSummaryStats = (duration) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/homeScreen/getRevenueSummary/" + duration, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

/////////////////////////////////// Dashboard Functions //////////////////////////////////

// Create a monthly data array for the current year
const currentDate = new Date();
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1; // Adding 1 to get the current month (1-12)

// const RevenueYearlyData = Array.from({ length: 12 }, (_, index) => {
//   const month = index + 1; // Months are 1-indexed (1-12)
//   const revenueEntry = dashboardStats?.webRevenue.find(
//     (entry) => entry._id.year === currentYear && new Date(entry.createdAt).getMonth() + 1 === month
//   );
//   return revenueEntry ? revenueEntry.totalSum : 0;
// });

export const generateYearlyDataArray = (dataArray, xAxisArray) => {
  const monthNameToNumber = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };

  return xAxisArray?.map((monthName) => {
    const monthNumber = monthNameToNumber[monthName];
    const revenueEntry = dataArray?.find((entry) => entry?._id.month === monthNumber);
    return revenueEntry ? revenueEntry?.totalSum : 0;
  });
};
// Create a thirty days data array for webRevenue
// const RevenueMontlyData = Array.from({ length: 30 }, (_, index) => {
//   const day = index + 1;
//   const revenueEntry = dashboardStats?.webRevenue?.find(
//     (entry) => entry._id.year === currentYear && entry._id.month === currentMonth && new Date(entry.createdAt).getDate() === day
//   );
//   return revenueEntry ? revenueEntry.totalSum : 0;
// });
// export const generateMonthlyDataArray = (dataArray) =>
//   Array.from({ length: 30 }, (_, index) => {
//     const day = index + 1;
//     const revenueEntry = dataArray?.find(
//       (entry) => entry._id.year === currentYear && entry._id.month === currentMonth && new Date(entry.createdAt).getDate() === day
//     );
//     return revenueEntry ? revenueEntry.totalSum : 0;
//   });
// export const generateMonthlyDataArray = (dataArray) => {
//   return Array.from({ length: 30 }, (_, index) => {
//     const day = index + 1;
//     const revenueEntry = dataArray?.find((entry) => entry._id.year === currentYear && entry._id.month === currentMonth && entry._id.day === day);
//     return revenueEntry ? revenueEntry.totalSum : 0;
//   });
// };

export const generateMonthlyDataArray = (dataArray, xAxisArray) => {
  return xAxisArray?.map((day) => {
    const dayOfMonth = parseInt(day.split(" ")[1], 10); // Extract the day of the month
    const revenueEntry = dataArray?.find((entry) => entry._id.day === dayOfMonth);
    return revenueEntry ? revenueEntry.totalSum : 0;
  });
};

// Create a seven days data array for webRevenue
// const RevenueWeeklyData = Array.from({ length: 7 }, (_, index) => {
//   const day = currentDate.getDate() - currentDate.getDay() + index;
//   const revenueEntry = dashboardStats?.webRevenue?.find(
//     (entry) =>
//       entry._id.year === currentYear && entry._id.month === currentMonth && entry._id.weekInMonth === currentWeekInMonth && entry._id.day === day
//   );
//   return revenueEntry ? revenueEntry.totalSum : 0;
// });

// export const generateWeeklyDataArray = (dataArray, xAxisArray) => {
//   return xAxisArray?.map((day) => {
//     const revenueEntry = dataArray?.find((entry) => entry._id.year === currentYear && entry._id.month === currentMonth && entry._id.day === day);
//     return revenueEntry ? revenueEntry.totalSum : 0;
//   });
// };

// export const generateWeeklyDataArray = (dataArray, xAxisArray) => {
//   const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

//   return xAxisArray?.map((dayName) => {
//     const dayIndex = dayNames?.indexOf(dayName);
//     const day = new Date(currentYear, currentMonth - 1, 1).getDay(); // Get the day of the week for the first day of the current month
//     const targetDay = ((dayIndex - day + 7) % 7) + 1; // Calculate the target day of the week

//     console.log(targetDay, "targetDay");
//     const revenueEntry = dataArray?.find(
//       (entry) => entry?._id.year === currentYear && entry?._id.month === currentMonth && entry?._id.day === targetDay
//     );
//     return revenueEntry ? revenueEntry?.totalSum : 0;
//   });
// };

export const generateWeeklyDataArray = (dataArray) => {
  const currentMonth = new Date().getMonth() + 1; // Current month is 1-indexed
  const currentYear = new Date().getFullYear();
  const last7DaysArray = [];

  for (let i = 6; i >= 0; i--) {
    const day = new Date(currentDate);
    day.setDate(currentDate.getDate() - i);
    last7DaysArray.push(day.getDate());
  }

  return last7DaysArray?.map((dayNumber) => {
    const revenueEntry = dataArray?.find(
      (entry) =>
        // entry?._id.year === currentYear &&
        // entry?._id.month === currentMonth &&
        entry?._id.day === dayNumber
    );
    return revenueEntry ? revenueEntry.totalSum : 0;
  });
};

export const generateCustomDateDataArray = (dataArray) => {
  const hoursArray = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];

  // Initialize an array to store the results
  const resultArray = [];

  // Create a map to efficiently access totalSum by hour
  const hourTotalSumMap = dataArray?.reduce((acc, entry) => {
    const hour = entry?._id?.hour?.toString().padStart(2, "0");
    acc[hour] = entry?.totalSum;
    return acc;
  }, {});

  // Iterate through the hoursArray and add totalSum if available
  hoursArray.forEach((hour) => {
    const totalSum = hourTotalSumMap?.[hour] || 0; // Ensure that hourTotalSumMap is defined
    resultArray.push(totalSum || 0);
  });
  return resultArray;
};

// Function to calculate the weekInMonth for a given date
// function getWeekInMonth(date) {
//   const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
//   const daysOffset = firstDayOfMonth.getDay();
//   const currentDay = date.getDate();
//   return Math.ceil((currentDay + daysOffset) / 7);
// }

// Create a thirty days data array for appleRevenue
// const appleRevenueDataArray = Array.from({ length: 30 }, (_, index) => {
//   const day = index + 1;
//   const revenueEntry = appleRevenue.find(
//     (entry) => entry._id.year === currentYear && entry._id.month === currentMonth && new Date(entry.createdAt).getDate() === day
//   );
//   return revenueEntry ? revenueEntry.totalSum : 0;
// });

export const generateYearlyXAxisArray = () => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const currentMonthIndex = new Date().getMonth(); // Get the current month index (0-indexed)
  const pastMonths = [];

  for (let i = 11; i >= 0; i--) {
    const monthIndex = (currentMonthIndex + 12 - i) % 12;
    pastMonths.push(months[monthIndex]);
  }

  return pastMonths;
};

export const generateMonthlyXAxisArray = () => {
  const numDays = 30;
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const xAxisArray = Array.from({ length: numDays }, (_, index) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - numDays + index);
    const monthAbbreviation = months[currentDate.getMonth()];
    const dayOfMonth = currentDate.getDate();
    return `${monthAbbreviation} ${dayOfMonth}`;
  });

  const currentDate = new Date();
  const currentMonthAbbreviation = months[currentDate.getMonth()];
  const currentDayOfMonth = currentDate.getDate();
  xAxisArray.push(`${currentMonthAbbreviation} ${currentDayOfMonth}`);

  return xAxisArray;
};

export const generateWeeklyXAxisArray = () => {
  // const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const today = new Date();
  const xAxisArray = [];

  for (let i = 6; i >= 0; i--) {
    const day = new Date();
    day.setDate(today.getDate() - i);
    const dayIndex = day.getDay();
    const formattedDate = `${day.getDate()} ${months[day.getMonth()]}`;
    xAxisArray.push(formattedDate);
  }
  return xAxisArray;
};

export const TwentyFourHoursArray = Array.from({ length: 24 }, (_, index) => (index < 10 ? `0${index}` : `${index}`));

export const getCategories = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/categories/getAllWithFilter", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const getLectureCategories = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/lectureCategories/getAllWithSubcategories", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addLectureCategory = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/lectureCategories", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateLectureCategory = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectureCategories/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteLectureCategoryPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/lectureCategories/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteLectureCategory = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/lectureCategories/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreLectureCategory = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/lectureCategories/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortLectureCategories = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectureCategories/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
// Workout categories

export const getWorkoutCategories = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/workoutCategories/getAll", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addWorkoutCategory = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/workoutCategories", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateWorkoutCategory = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/workoutCategories/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteWorkoutCategoryPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/workoutCategories/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWorkoutCategory = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/workoutCategories/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreWorkoutCategory = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/workoutCategories/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortWorkoutCategories = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/workoutCategories/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//

// Workout sub categories

export const getWorkoutSubcategories = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/workoutSubcategories/getCategorySubcategories",
      { categoryId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addWorkoutSubcategory = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/workoutSubcategories", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateWorkoutSubcategory = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/workoutSubcategories/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteWorkoutSubcategoryPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/workoutSubcategories/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWorkoutSubcategory = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/workoutSubcategories/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreWorkoutSubcategory = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/workoutSubcategories/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortWorkoutSubcategories = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/workoutSubcategories/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//

// Workouts

export const getAdditionalWorkouts = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/additionalWorkouts/getSubcategoryWorkouts",
      { subcategoryId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addAdditionalWorkout = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/additionalWorkouts", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateAdditionalWorkout = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/additionalWorkouts/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteAdditionalWorkoutPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/additionalWorkouts/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteAdditionalWorkout = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/additionalWorkouts/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreAdditionalWorkout = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/additionalWorkouts/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateAdditionalWorkoutText = (payload, id) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/additionalWorkouts/updateText/" + id, payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortAdditionalWorkouts = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/additionalWorkouts/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
// Workout Overview

export const getWorkoutOviewList = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/additionalWorkoutOverview/getWorkoutOverviews",
      { workoutId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const addWorkoutOverview = (payload) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/additionalWorkoutOverview", payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const updateWorkoutOverview = (payload, id) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/additionalWorkoutOverview/" + id, payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWorkoutOverviewPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/additionalWorkoutOverview/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//////////////// Sorting //////////////////

export const sortWorkoutOverviews = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/additionalWorkoutOverview/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Workout warmup videos

export const getWorkoutWarmupVideos = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/additionalWorkoutWarmup/getWorkoutWarmpus",
      { workoutId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addWorkoutWarmupVideo = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/additionalWorkoutWarmup", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateWorkoutWarmupVideo = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/additionalWorkoutWarmup/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteWorkoutWarmupVideoPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/additionalWorkoutWarmup/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWorkoutWarmupVideo = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/additionalWorkoutWarmup/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreWorkoutWarmupVideo = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/additionalWorkoutWarmup/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortWorkoutWarmupVideos = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/additionalWorkoutWarmup/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//
// Workout warmup pdfs

export const getWorkoutWarmupPdfs = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/additionalWorkoutWarmupPdf/getWorkoutWarmupPdf",
      { workoutWarmupId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addWorkoutWarmupPdf = (warmup) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/additionalWorkoutWarmupPdf", warmup, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateWorkoutWarmupPdf = (id, pdfData) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/additionalWorkoutWarmupPdf/" + id, pdfData, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWorkoutWarmupPdfPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/additionalWorkoutWarmupPdf/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWorkoutWarmupPdf = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/additionalWorkoutWarmupPdf/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreWorkoutWarmupPdf = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/additionalWorkoutWarmupPdf/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortWorkoutWarmupPdfs = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/additionalWorkoutWarmupPdf/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//

// Workout Excercise category

export const getWorkoutExerciseCategories = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/additionalWorkoutExerciseCategories/getWorkoutExcerciseCategories",
      { workoutId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addWorkoutExerciseCategory = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/additionalWorkoutExerciseCategories", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateWorkoutExerciseCategory = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/additionalWorkoutExerciseCategories/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteWorkoutExerciseCategoryPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/additionalWorkoutExerciseCategories/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWorkoutExerciseCategory = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/additionalWorkoutExerciseCategories/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreWorkoutExerciseCategory = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/additionalWorkoutExerciseCategories/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortWorkoutExerciseCategories = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/additionalWorkoutExerciseCategories/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//

// Workout exercise category videos

export const getWorkoutExerciseCategoryVideos = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/additionalWorkoutExercise/getWeekDayCategoriesExcercise",
      { categoryId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addWorkoutExerciseCategoryVideo = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/additionalWorkoutExercise", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateWorkoutExerciseCategoryVideo = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/additionalWorkoutExercise/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteWorkoutExerciseCategoryVideoPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/additionalWorkoutExercise/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWorkoutExerciseCategoryVideo = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/additionalWorkoutExercise/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreWorkoutExerciseCategoryVideo = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/additionalWorkoutExercise/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const cloneWorkoutExerciseVideo = (id) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/clone/workoutExerciseClone/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortWorkoutExerciseVideos = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/additionalWorkoutExercise/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//

// Workout exercise category swap videos

export const getWorkoutExerciseSwapVideos = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/additionalWorkoutExerciseSwap/getAdditionalWorkoutExerciseSwaps",
      { exerciseId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addWorkoutExerciseSwapVideo = (exercise) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/additionalWorkoutExerciseSwap", exercise, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateWorkoutExerciseSwapVideo = (id, exercise) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/additionalWorkoutExerciseSwap/" + id, exercise, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteWorkoutExerciseSwapVideoPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/additionalWorkoutExerciseSwap/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWorkoutExerciseSwapVideo = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/additionalWorkoutExerciseSwap/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreWorkoutExerciseSwapVideo = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/additionalWorkoutExerciseSwap/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortWorkoutExerciseSwapVideos = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/additionalWorkoutExerciseSwap/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//

// Final Assesment Result

// export const getFinalAssesmentResultsList = (id) => {
//     return axios
//         .post(
//             process.env.REACT_APP_APIPATH + '/programWeekDayResult/getWeekDayResults',
//             {weekDayId:id},
//             {
//                 headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
//             }
//         )
//         .then(response => {
//             return response
//         })
//         .catch(err => {
//             return err.response;
//         })
// }

// export const addFinalAssesmentResult = result => {
//     return axios
//         .post(
//             process.env.REACT_APP_APIPATH + '/programWeekDayResult',
//             result,
//             {
//                 headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
//             }
//         )
//         .then(response => {
//             return response
//         })
//         .catch(err => {
//             return err.response;
//         })
// }

// export const updateFinalAssesmentResult = (id, result) => {
//     return axios
//         .put(
//             process.env.REACT_APP_APIPATH + '/programWeekDayResult/' + id,
//             result,
//             {
//                 headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
//             }
//         )
//         .then(response => {
//             return response
//         })
//         .catch(err => {
//             return err.response;
//         })
// }

// export const deleteFinalAssesmentResultPermanently = id => {
//     return axios
//         .delete(
//             process.env.REACT_APP_APIPATH + '/programWeekDayResult/permanent/' + id,
//             {
//                 headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
//             }
//         )
//         .then(response => {
//             return response
//         })
//         .catch(err => {
//             return err.response;
//         })
// }
// export const deleteFinalAssesmentResult = id => {
//     return axios
//         .delete(
//             process.env.REACT_APP_APIPATH + '/programWeekDayResult/' + id,
//             {
//                 headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
//             }
//         )
//         .then(response => {
//             return response
//         })
//         .catch(err => {
//             return err.response;
//         })
// }

// export const restoreFinalAssesmentResult = id => {
//     return axios
//         .put(
//             process.env.REACT_APP_APIPATH + '/programWeekDayResult/restore/' + id,{},
//             {
//                 headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
//             }
//         )
//         .then(response => {
//             return response
//         })
//         .catch(err => {
//             return err.response;
//         })
// }
// //

// Programs

export const getProgramsList = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/program/getCategoryPrograms",
      { categoryId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addProgram = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/program", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateProgram = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/program/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteProgramPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/program/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteProgram = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/program/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreProgram = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/program/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getProgramDetails = (id) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/breadcrumbs/program/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortPrograms = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/program/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Week lecture categories

export const getWeekLectureCategories = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekLectureCategories/getWeekLectureCategory",
      { weekId: id, v: 1 },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addWeekLectureCategory = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekLectureCategories", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateWeekLectureCategory = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureCategories/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteWeekLectureCategoryPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectureCategories/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWeekLectureCategory = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectureCategories/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreWeekLectureCategory = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programWeekLectureCategories/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortWeekLectureCategory = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureCategories/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Week lecture Subcategories

export const getWeekLectureSubCategories = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekLectureSubcategories/getCategorySubcategories",
      { categoryId: id, v: 1 },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addWeekLectureSubcategory = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekLectureSubcategories", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateWeekLectureSubcategory = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureSubcategories/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteWeekLectureSubcategoryPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectureSubcategories/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWeekLectureSubcategory = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectureSubcategories/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreWeekLectureSubcategory = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programWeekLectureSubcategories/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortWeekLectureSubcategory = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureSubcategories/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//Week Subcategory Lecture

export const getProgramWeekLectures = (subcategoryId) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekLectures/getSubcategoryLectures",
      { subcategoryId },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addProgramWeekLecture = (lecture) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekLectures", lecture, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateProgramWeekLecture = (id, lecture) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectures/" + id, lecture, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteProgramWeekLecture = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectures/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreProgramWeekLecture = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programWeekLectures/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteProgramWeekLecturePermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectures/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateProgramWeekLectureText = (payload, id) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectures/updateText/" + id, payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortProgramWeekLectures = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectures/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Lectures Overview

export const getProgramWeekLecturesOverviewList = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekLectureOverview/getLectureOverviews",
      { lectureId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const addProgramWeekLectureOverview = (payload) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekLectureOverview", payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const updateProgramWeekLectureOverview = (payload, id) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureOverview/" + id, payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteProgramWeekLectureOverviewPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectureOverview/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//////////////// Sorting //////////////////

export const sortProgramWeekLectureOverviews = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureOverview/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Lectures warmup videos

export const getProgramWeekLectureWarmups = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekLectureWarmup/getLectureWarmups",
      { lectureId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addProgramWeekLectureWarmup = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekLectureWarmup", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateProgramWeekLectureWarmup = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureWarmup/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteProgramWeekLectureWarmupPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectureWarmup/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteProgramWeekLectureWarmup = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectureWarmup/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreProgramWeekLectureWarmup = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programWeekLectureWarmup/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortProgramWeekLectureWarmups = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureWarmup/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//
// Lectures warmup pdfs

export const getProgramWeekLectureWarmupPdfs = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekLectureWarmupPdf/getLectureWarmupPdf",
      { lectureWarmupId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addProgramWeekLectureWarmupPdf = (warmup) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekLectureWarmupPdf", warmup, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateProgramWeekLectureWarmupPdf = (id, pdfData) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureWarmupPdf/" + id, pdfData, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteProgramWeekLectureWarmupPdfPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectureWarmupPdf/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteProgramWeekLectureWarmupPdf = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectureWarmupPdf/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreProgramWeekLectureWarmupPdf = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programWeekLectureWarmupPdf/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortProgramWeekLectureWarmupPdfs = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureWarmupPdf/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//

// Lectures Excercise category

export const getProgramWeekLectureExerciseCategories = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekLectureExerciseCategories/getLectureExcerciseCategories",
      { lectureId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addProgramWeekLectureExerciseCategory = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekLectureExerciseCategories", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateProgramWeekLectureExerciseCategory = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureExerciseCategories/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteProgramWeekLectureExerciseCategoryPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectureExerciseCategories/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteProgramWeekLectureExerciseCategory = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectureExerciseCategories/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreProgramWeekLectureExerciseCategory = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programWeekLectureExerciseCategories/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortProgramWeekLectureExerciseCategories = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureExerciseCategories/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//

export const updateProgramWeekExerciseText = (payload, id) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureExerciseCategories/updateText/" + id, payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Lecture exercise category videos

export const getProgramWeekLectureExerciseCategoryVideos = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekLectureExercise/getWeekDayCategoriesExcercise",
      { categoryId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addProgramWeekLectureExerciseCategoryVideo = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekLectureExercise", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateProgramWeekLectureExerciseCategoryVideo = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureExercise/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteProgramWeekLectureExerciseCategoryVideoPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectureExercise/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteProgramWeekLectureExerciseCategoryVideo = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectureExercise/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreProgramWeekLectureExerciseCategoryVideo = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programWeekLectureExercise/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const cloneProgramWeekLectureExerciseVideo = (id) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/clone/programWeekLectureExerciseClone/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortProgramWeekLectureExerciseVideos = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureExercise/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//

// Lecture exercise category swap videos

export const getProgramWeekLectureExerciseSwapVideos = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekLectureExerciseSwap/getLectureExerciseSwaps",
      { exerciseId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addProgramWeekLectureExerciseSwapVideo = (exercise) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekLectureExerciseSwap", exercise, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateProgramWeekLectureExerciseSwapVideo = (id, exercise) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureExerciseSwap/" + id, exercise, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteProgramWeekLectureExerciseSwapVideoPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectureExerciseSwap/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteProgramWeekLectureExerciseSwapVideo = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectureExerciseSwap/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreProgramWeekLectureExerciseSwapVideo = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programWeekLectureExerciseSwap/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortProgramWeekLectureExerciseSwapVideos = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureExerciseSwap/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Week lecture category videos

export const getWeekLectureCategoryVideos = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekLectureCategoryVideos/getWeekLectureCategoryVideos",
      { categoryId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addWeekLectureCategoryVideo = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekLectureCategoryVideos", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateWeekLectureCategoryVideo = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureCategoryVideos/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteWeekLectureCategoryVideoPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectureCategoryVideos/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWeekLectureCategoryVideo = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekLectureCategoryVideos/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreWeekLectureCategoryVideo = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programWeekLectureCategoryVideos/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortWeekLectureCategoryVideo = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekLectureCategoryVideos/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Program Week PDF

export const getProgramWeekPDF = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekPdf/getWeekPdf",
      { weekId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addProgramWeekPDF = (payload) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekPdf", payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateProgramWeekPDF = (id, payload) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekPdf/" + id, payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteProgramWeekPDFPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekPdf/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const sortIntroWeekPDF = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekPdf/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
// Programs Weeks

export const getProgramWeekOverview = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekOverview/getWeekOverviews",
      { weekId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const addWeekOverview = (payload) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekOverview", payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const updateWeekOverview = (payload, id) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekOverview/" + id, payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWeekOverviewPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekOverview/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateIntroWeekTabs = (payload, id) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeeks/updateTab/" + id, payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortIntroWeekOverview = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekOverview/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getProgramWeekCalendar = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekCalendar/getWeekCalendar",
      { weekId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const addProgramWeekCalendar = (payload) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekCalendar", payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateProgramWeekCalendar = (payload, id) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekCalendar/" + id, payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteProgramWeekCalendarPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekCalendar/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const sortIntroWeekCalendar = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekCalendar/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getProgramWeeksList = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeeks/getProgramWeeks",
      { programId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addWeek = (program) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeeks", program, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateWeek = (id, program) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeeks/" + id, program, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteWeekPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeeks/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWeek = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeeks/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreWeek = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programWeeks/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const cloneWeek = (id) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/clone/weekClone/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getWeekDetails = (id) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/breadcrumbs/week/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortProgramWeeks = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeeks/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Programs Week Days

export const getWeekDaysList = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekDays/getWeekDays",
      { weekId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addDay = (program) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekDays", program, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateDay = (id, program) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekDays/" + id, program, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteDayPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekDays/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteDay = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekDays/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreDay = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programWeekDays/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateWeekDayText = (payload, id) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekDays/updateText/" + id, payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const cloneWeekDay = (id) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/clone/dayClone/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getDayDetails = (id) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/breadcrumbs/day/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortProgramWeekDays = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekDays/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Programs Week Day Overview

export const getWeekDayOviewList = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekDayOverview/getWeekDayOverviews",
      { weekDayId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const addWeekDayOverview = (payload) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekDayOverview", payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const updateWeekDayOverview = (payload, id) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekDayOverview/" + id, payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWeekDayOverviewPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekDayOverview/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortWeekDayOverview = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekDayOverview/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Week day warmup videos

export const getWeekDayWarmupVideos = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekDayWarmup/getWeekDayWarmpus",
      { weekDayId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addWeekDayWarmupVideo = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekDayWarmup", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateWeekDayWarmupyVideo = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekDayWarmup/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteWeekDayWarmupVideoPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekDayWarmup/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWeekDayWarmupVideo = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekDayWarmup/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreWeekDayWarmupVideo = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programWeekDayWarmup/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortWeekDayWarmupVideo = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekDayWarmup/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//
// Week day warmup pdfs

export const getWeekDayWarmupPdfs = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekDayWarmupPdf/getWeekDayWarmupPdf",
      { weekDayWarmupId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addWeekDayWarmupPdf = (warmup) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekDayWarmupPdf", warmup, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateWeekDayWarmupPdf = (id, pdfData) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekDayWarmupPdf/" + id, pdfData, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWeekDayWarmupPdfPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekDayWarmupPdf/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWeekDayWarmupPdf = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekDayWarmupPdf/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreWeekDayWarmupPdf = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programWeekDayWarmupPdf/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortWeekDayWarmupPdf = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekDayWarmupPdf/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//

// Programs Week Day Excercise category

export const getWeekDayExerciseCategories = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekDayExerciseCategories/getWeekDayExcerciseCategories",
      { weekDayId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addWeekDayExerciseCategory = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekDayExerciseCategories", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateWeekDayExerciseCategory = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekDayExerciseCategories/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteWeekDayExerciseCategoryPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekDayExerciseCategories/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWeekDayExerciseCategory = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekDayExerciseCategories/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreWeekDayExerciseCategory = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programWeekDayExerciseCategories/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const cloneWeekDayExerciseCategory = (id) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/clone/dayExerciseCategoryClone/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortWeekDayExerciseCategories = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekDayExerciseCategories/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//

// Week exercise category videos

export const getWeekDayExerciseCategoryVideos = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekDayExercise/getWeekDayCategoriesExcercise",
      { categoryId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addWeekDayExerciseCategoryVideo = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekDayExercise", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateWeekDayExerciseCategoryVideo = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekDayExercise/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteWeekDayExerciseCategoryVideoPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekDayExercise/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWeekDayExerciseCategoryVideo = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekDayExercise/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreWeekDayExerciseCategoryVideo = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programWeekDayExercise/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const cloneWeekDayExerciseCategoryVideo = (id) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/clone/dayExerciseClone/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortWeekDayExerciseVideos = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekDayExercise/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//

// Week exercise category swap videos

export const getWeekDayExerciseSwapVideos = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekDayExerciseSwap/getWeekDayExerciseSwaps",
      { exerciseId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addWeekDayExerciseSwapVideo = (exercise) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekDayExerciseSwap", exercise, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateWeekDayExerciseSwapVideo = (id, exercise) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekDayExerciseSwap/" + id, exercise, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteWeekDayExerciseSwapVideoPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekDayExerciseSwap/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteWeekDayExerciseSwapVideo = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekDayExerciseSwap/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreWeekDayExerciseSwapVideo = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programWeekDayExerciseSwap/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const cloneWeekDayExerciseSwapVideo = (id) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/clone/dayExerciseSwapClone/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortWeekDayExerciseSwapVideos = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekDayExerciseSwap/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//

// Final Assesment Result

export const getFinalAssesmentResultsList = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programWeekDayResult/getWeekDayResults",
      { weekDayId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addFinalAssesmentResult = (result) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programWeekDayResult", result, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateFinalAssesmentResult = (id, result) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programWeekDayResult/" + id, result, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteFinalAssesmentResultPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekDayResult/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteFinalAssesmentResult = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programWeekDayResult/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreFinalAssesmentResult = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programWeekDayResult/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getSuggestionsList = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/programCategories/getProgramCategoryDetail", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getReferenceDaysList = (programId) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programDays/getProgramDays",
      { programId: programId },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const postReferenceDay = (title, program) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programDays",
      { title: title, program: program },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const updateReferenceDay = (title, program, id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programDays/" + id,
      { title: title, program: program },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteReferenceDayPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programDays/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//program swaps list

export const getProgramSwapsList = (programId) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/programSwaps?program=" + programId, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const postProgramSwap = (title, program) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/programSwaps",
      { title: title, program: program },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const updateProgramSwap = (title, program, id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programSwaps/" + id,
      { title: title, program: program },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteProgramSwapPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programSwaps/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getMicrocyclesList = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/program/getCategoryMicrocycles",
      { categoryId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//

// Program categories

export const getProgramCategories = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/programCategories/getAll", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addProgramCategory = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/programCategories", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateProgramCategory = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programCategories/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteProgramCategoryPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programCategories/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteProgramCategory = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/programCategories/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreProgramCategory = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/programCategories/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getProgramCategoryDetails = (id) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/breadcrumbs/category/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortProgramCategories = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/programCategories/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Roles Management

export const getRolesList = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/roles/getAll", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addRole = (role) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/roles", role, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateRole = (id, role) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/roles/" + id, role, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteRolePermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/roles/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteRole = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/roles/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreRole = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/roles/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Role permissions

export const getAllPermissionsList = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/permissionType/getPermossionTypesWithPermissions", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const getAssignedPermissionsList = (id) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/roles/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const assignPermissions = (payload) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/roles/assignPermissions", payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Permission types

export const getAllPermissionTypes = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/permissionType/getAll", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addPermissionType = (permission) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/permissionType", permission, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updatePermissionType = (id, permission) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/permissionType/" + id, permission, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deletePermissionTypePermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/permissionType/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deletePermissionType = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/permissionType/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restorePermissionType = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/permissionType/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortPermissionTypesList = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/permissionType/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Permissions crud

export const getAllPermissions = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/permissions/getAll", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addPermission = (permission) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/permissions", permission, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updatePermission = (id, permission) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/permissions/" + id, permission, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deletePermissionPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/permissions/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deletePermission = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/permissions/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restorePermission = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/permissions/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
// SM Team

export const getSmTeam = (from, limit) => {
  return axios
    .get(process.env.REACT_APP_COMMUNITY_APIPATH + `/posts/sm-team-updates?from=${from}&limit=${limit}`, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addSmTeamPost = (post) => {
  return axios
    .post(process.env.REACT_APP_COMMUNITY_APIPATH + "/posts/create-post", post, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateSmTeamPost = (id, post) => {
  return axios
    .put(process.env.REACT_APP_COMMUNITY_APIPATH + "/posts/update/" + id, post, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteSmTeamPost = (id, post) => {
  return axios
    .delete(process.env.REACT_APP_COMMUNITY_APIPATH + "/posts/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const uploadAttachments = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return axios
    .post(process.env.REACT_APP_COMMUNITY_APIPATH + "/media", formData, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
// Admin Users

export const getAllAdminUsers = () => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/users/admins/getAll",
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addAdminUser = (user) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/users/admins/create", user, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateAdminUser = (id, user) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/users/admins/" + id, user, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteAdminUserPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/users/admins/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteAdminUser = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/users/admins/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreAdminUser = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/users/admins/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// App sliders

export const getAppSliders = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/banners/getAll", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const getAcademyAppSliders = (type) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + `/banners/getAllAcademyBanners?type=${type}`, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addAppSlider = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/banners", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateAppSlider = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/banners/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteAppSliderPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/banners/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteAppSlider = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/banners/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreAppSlider = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/banners/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const sortAppSliders = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/banners/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////////////////// All lecture subcategories ///////////////////////////

export const getAllLectureSubcategories = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/lectureSubcategories", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////////////////// All workouts ///////////////////////////

export const getAllWorkoutsList = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/additionalWorkouts", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////////////////// Stripe ///////////////////////////

export const getAllMembershipPlansList = (currency, token, origin) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/stripe/products/" + currency + "/" + origin, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getPaymenIntent = (payload, token) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/stripe/payment", payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const checkSalesStatus = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/homeScreen/checkSales", {
      headers: { "Content-Type": "application/json", Authorization: `` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const checkPromotionStatus = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/homeScreen/checkPromotion", {
      headers: { "Content-Type": "application/json", Authorization: `` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const stripePaymentResponse = (payload, token) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/stripe/addUserSubscription", payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const validateCoupon = (payload, token) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/coupons/validate", payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getCurrentSubscription = (token) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/userSubscriptions/getUserCurrentSubscription", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

/////////////////////////////// Date format //////////////////////////////

export const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

/////////////////////////////// Date format with time //////////////////////////////

// export const formatDateWithTime = (dateStr) => {
//     const date = new Date(dateStr);
//     const day = String(date.getDate()).padStart(2, '0');
//     const month = String(date.getMonth() + 1).padStart(2, '0');
//     const year = date.getFullYear();
//     const hours = String(date.getHours()).padStart(2, '0');
//     const minutes = String(date.getMinutes()).padStart(2, '0');
//     const seconds = String(date.getSeconds()).padStart(2, '0');
//     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
//   };

export const formatDateWithTime = (dateStr) => {
  const date = new Date(dateStr);
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };
  return date.toLocaleString("en-GB", options);
};

// export const formatDateWithTime = (dateStr) => {
//     const date = new Date(dateStr);
//     return date.toLocaleString('en-GB');
//   };

/////////////////////////////// Currency Symbol //////////////////////////////

export const getCurrencySymbol = (currencyCode) => {
  try {
    const currencyFormatter = new Intl.NumberFormat("en-US", { style: "currency", currency: currencyCode });
    const currencyParts = currencyFormatter.formatToParts(0);
    const currencySymbolPart = currencyParts.find((part) => part.type === "currency");
    if (currencySymbolPart) {
      return currencySymbolPart.value;
    }
  } catch (error) {
    console.error("Error retrieving currency symbol:", error);
  }

  return ""; // Return an empty string if the currency symbol is not found or there was an error
};

/////////////////////////////// Subscriptions //////////////////////////////

export const getAllSubscriptionsList = (filters) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/userSubscriptions/getAll", filters, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const downloadSubscriptionsList = (filters) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/userSubscriptions/export-csv", filters, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

/////////////////////////////// Transactions //////////////////////////////

export const getAllTransactionsList = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/transactionHistory/getAll", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

/////////////////////////////// Refund Transaction //////////////////////////////

export const refundTransaction = (id, payload) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/stripe/stripeRefund/" + id, payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
/////////////////////////////// Coupons //////////////////////////////

export const getAllCouponsList = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/coupons/getCouponsWithPromo", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const createCoupon = (payload) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/coupons", payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteCouponPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/coupons/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Coupon codes

export const getCouponCodesList = (id) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/coupons/promo-codes/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const createCouponCode = (payload, id) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + `/coupons/${id}/promotion-codes`, payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteCouponCode = (id, status) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/coupons/promoCodeDelete",
      { promoCodeId: id, status },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getAllProductsList = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/stripe/products/usd", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getAllCustomersList = (query) => {
  return axios
    .get(`${process.env.REACT_APP_APIPATH}/stripe/customerList${query ? `?search=${query}` : ""}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.usertoken}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

/////////////////////////////// Lectures //////////////////////////////
export const getLectureSubcategories = ({ categoryId, v }) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/lectureSubcategories/getCategorySubcategories",
      { categoryId, v },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addLectureSubcategory = (subcategory) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/lectureSubcategories", subcategory, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateLectureSubcategory = (id, filter) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectureSubcategories/" + id, filter, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteLectureSubcategoryPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/lectureSubcategories/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteLectureSubcategory = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/lectureSubcategories/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreLectureSubcategory = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/lectureSubcategories/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortLectureSubcategories = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectureSubcategories/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getSubcategoryLectures = (subcategoryId) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/lectures/getSubcategoryLectures",
      { subcategoryId },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getLecturesCount = (filters) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/lectures/getAllCount", filters, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const loadLectureThumbnails = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/lectures/loadThumbnails", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const addLecture = (video) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/lectures", video, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateLecture = (id, video) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectures/" + id, video, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteLecture = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/lectures/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreLecture = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/lectures/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteLecturePermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/lectures/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateLectureText = (payload, id) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectures/updateText/" + id, payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortLectures = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectures/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Lectures Overview

export const getLecturesOverviewList = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/lectureOverview/getLectureOverviews",
      { lectureId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const addLectureOverview = (payload) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/lectureOverview", payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const updateLectureOverview = (payload, id) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectureOverview/" + id, payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteLectureOverviewPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/lectureOverview/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//////////////// Sorting //////////////////

export const sortLectureOverviews = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectureOverview/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Lectures warmup videos

export const getLectureWarmupVideos = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/lectureWarmup/getLectureWarmups",
      { lectureId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addLectureWarmupVideo = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/lectureWarmup", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateLectureWarmupVideo = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectureWarmup/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteLectureWarmupVideoPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/lectureWarmup/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteLectureWarmupVideo = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/lectureWarmup/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreLectureWarmupVideo = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/lectureWarmup/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortLectureWarmupVideos = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectureWarmup/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//
// Lectures warmup pdfs

export const getLectureWarmupPdfs = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/lectureWarmupPdf/getLectureWarmupPdf",
      { lectureWarmupId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addLectureWarmupPdf = (warmup) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/lectureWarmupPdf", warmup, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateLectureWarmupPdf = (id, pdfData) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectureWarmupPdf/" + id, pdfData, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteLectureWarmupPdfPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/lectureWarmupPdf/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteLectureWarmupPdf = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/lectureWarmupPdf/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreLectureWarmupPdf = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/lectureWarmupPdf/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortLectureWarmupPdfs = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectureWarmupPdf/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//

// Lectures Excercise category

export const getLectureExerciseCategories = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/lectureExerciseCategories/getLectureExcerciseCategories",
      { lectureId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addLectureExerciseCategory = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/lectureExerciseCategories", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateLectureExerciseCategory = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectureExerciseCategories/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteLectureExerciseCategoryPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/lectureExerciseCategories/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteLectureExerciseCategory = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/lectureExerciseCategories/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreLectureExerciseCategory = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/lectureExerciseCategories/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateLectureExerciseCategoriesText = (payload, id) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectureExerciseCategories/updateText/" + id, payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortLectureExerciseCategories = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectureExerciseCategories/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//

// Lecture exercise category videos

export const getLectureExerciseCategoryVideos = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/lectureExercise/getWeekDayCategoriesExcercise",
      { categoryId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addLectureExerciseCategoryVideo = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/lectureExercise", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateLectureExerciseCategoryVideo = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectureExercise/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteLectureExerciseCategoryVideoPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/lectureExercise/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteLectureExerciseCategoryVideo = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/lectureExercise/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreLectureExerciseCategoryVideo = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/lectureExercise/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const cloneLectureExerciseVideo = (id) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/clone/lectureExerciseClone/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortLectureExerciseVideos = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectureExercise/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//

// Lecture exercise category swap videos

export const getLectureExerciseSwapVideos = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/lectureExerciseSwap/getLectureExerciseSwaps",
      { exerciseId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addLectureExerciseSwapVideo = (exercise) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/lectureExerciseSwap", exercise, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateLectureExerciseSwapVideo = (id, exercise) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectureExerciseSwap/" + id, exercise, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteLectureExerciseSwapVideoPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/lectureExerciseSwap/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteLectureExerciseSwapVideo = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/lectureExerciseSwap/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreLectureExerciseSwapVideo = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/lectureExerciseSwap/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////// Sorting //////////////////

export const sortLectureExerciseSwapVideos = (sortedArray) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/lectureExerciseSwap/update/sortNo", sortedArray, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
//

//////////////Academy Sliders updated apis//////////////////

////////////////Programs Apis//////////////////////

export const getAcademySliderProgramCategories = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/programCategories", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const getAcademySliderPrograms = (categoryId) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/program?category=" + categoryId, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const getAcademySliderProgramWeeks = (programId) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/programWeeks?program=" + programId, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const getAcademySliderProgramWeekDays = (weekDayId) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/programWeekDays?week=" + weekDayId, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

////////////////lecture Apis//////////////////////

export const getAcademySliderLectureCategories = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/lectureCategories", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getAcademySliderLectureSubcategories = (categoryId) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/lectureSubcategories?category=" + categoryId, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const getAcademySliderLectures = (subcategoryId) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/lectures?subcategory=" + subcategoryId, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

////////////////workout Apis//////////////////////

export const getAcademySliderWorkoutCategories = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/workoutCategories", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getAcademySliderWorkoutSubcategories = (categoryId) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/workoutSubcategories?category=" + categoryId, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const getAcademySliderWorkouts = (subcategoryId) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/additionalWorkouts?subcategory=" + subcategoryId, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

/////////notifications///////////////////////
export const notificationsList = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/notification", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addNotification = (notification) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/notification", notification, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateNotification = (id, notification) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/notification/" + id, notification, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteNotification = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/notification/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const reSendNotification = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/notification/resend/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Frequently Asked Questions
///////////////////// Categories ///////////////////////

export const faqCategoriesList = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/faqCategory/getAll", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addFaqCategory = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/faqCategory", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateFaqCategory = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/faqCategory/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteFaqCategoryPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/faqCategory/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteFaqCategory = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/faqCategory/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreFaqCategory = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/faqCategory/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

///////////////////////////// FAQs /////////////////////////////

export const getFaqsList = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/faqs/getCategoryFaqs",
      { categoryId: id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addFaq = (faq) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/faqs", faq, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateFaq = (id, faq) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/faqs/" + id, faq, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteFaqPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/faqs/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteFaq = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/faqs/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreFaq = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/faqs/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Community Admin Posts

export const getCommunityAdminPosts = () => {
  return axios
    .get(process.env.REACT_APP_COMMUNITY_APIPATH + "/posts/sm-team-updates?from=0&limit=1000000", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const createCommunityAdminPost = (payload) => {
  return axios
    .post(process.env.REACT_APP_COMMUNITY_APIPATH + "/posts", payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateCommunityAdminPost = (id, payload) => {
  return axios
    .put(process.env.REACT_APP_COMMUNITY_APIPATH + "/posts/" + id, payload, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteCommunityAdminPostPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_COMMUNITY_APIPATH + "/posts/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteCommunityAdminPost = (id) => {
  return axios
    .delete(process.env.REACT_APP_COMMUNITY_APIPATH + "/posts/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreCommunityAdminPost = (id) => {
  return axios
    .put(
      process.env.REACT_APP_COMMUNITY_APIPATH + "/posts/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// SM Community Posts

export const getSMCommunityPosts = ({ from, limit, username = "", search = "", dateFrom = "", dateTo = "" }) => {
  return axios
    .get(
      process.env.REACT_APP_COMMUNITY_APIPATH +
        `/posts/explore?limit=${limit}&from=${from}&v=1&username=${username}&search=${search}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteSMCommunityPost = (id) => {
  return axios
    .delete(process.env.REACT_APP_COMMUNITY_APIPATH + `/posts/${id}`, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getCommunityPostComments = ({ post = "", comment = "", from, limit }) => {
  return axios
    .get(process.env.REACT_APP_COMMUNITY_APIPATH + `/comments/getAll?post=${post}&limit=${limit}&from=${from}&v=1&comment=${comment}`, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getCommunityPostReplies = ({ comment, from, limit }) => {
  return axios
    .get(process.env.REACT_APP_COMMUNITY_APIPATH + `/comment-reply/getAll?comment=${comment}&limit=${limit}&from=${from}`, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteCommunityPostComment = (id) => {
  return axios
    .delete(process.env.REACT_APP_COMMUNITY_APIPATH + `/comments/${id}`, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Reported Community Posts

export const getReportedCommunityPosts = ({ from, limit, username = "", search = "", dateFrom = "", dateTo = "" }) => {
  return axios
    .get(process.env.REACT_APP_COMMUNITY_APIPATH + `/post-report/getAll?limit=${limit}&from=${from}&username=${username}&search=${search}&dateFrom=${dateFrom}&dateTo=${dateTo}`, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getReportedPostDetails = (id, from, limit) => {
  return axios
    .get(process.env.REACT_APP_COMMUNITY_APIPATH + `/post-report/get-reports?post=${id}&limit=${limit}&from=${from}`, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

//////////////////////////////////////

export const addCategory = (category) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/categories", category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateCategory = (id, category) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/categories/" + id, category, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteCategoryPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/categories/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteCategory = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/categories/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreCategory = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/categories/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getFilters = () => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/categories/getAllWithFilterAndVideosCount",
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addFilter = (filter) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/filters", filter, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateFilter = (id, filter) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/filters/" + id, filter, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteFilterPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/filters/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteFilter = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/filters/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreFilter = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/filters/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getVideos = (filters) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/videos/getAll", filters, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getVideosCount = (filters) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/videos/getAllCount", filters, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getMembers = (filters) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/users/members/getAll", filters, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getMemberDetails = (id) => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/users/getTransactionsByUserId/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getMembersCount = (filters) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/users/members/getAllCount", filters, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteMember = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/users/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const restoreMember = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/users/restore/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

// Activate user subscription //

export const activateUserSubscription = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/userSubscriptions/activateUserSubscription/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
// Activate user badge status //

export const updateUserBadgeStatus = (id) => {
  return axios
    .put(
      process.env.REACT_APP_APIPATH + "/users/updateUserBadge/" + id,
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addVideo = (video) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/videos/create", video, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateVideo = (id, video) => {
  return axios
    .put(process.env.REACT_APP_APIPATH + "/videos/" + id, video, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteVideoPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/videos/permanent/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const importVideos = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return axios
    .post(process.env.REACT_APP_APIPATH + "/videos/import", formData, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const loadThumbnails = () => {
  return axios
    .get(process.env.REACT_APP_APIPATH + "/videos/loadThumbnails", {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addMedia = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return axios
    .post(process.env.REACT_APP_APIPATH + "/media", formData, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getMedia = () => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/media/getAll",
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteMediaPermanently = (id) => {
  return axios
    .delete(process.env.REACT_APP_APIPATH + "/media/" + id, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addEditAdmin = (admin) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/api/addEditAdmin.php", admin, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteAdmin = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/addEditAdmin.php",
      { id, delete: true },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getAdmins = () => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/admin.php",
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getAdmin = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/admin.php",
      { id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addEditEmployee = (admin) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/api/addEditEmployee.php", admin, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteEmployee = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/addEditEmployee.php",
      { id, delete: true },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getEmployees = () => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/employee.php",
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getEmployee = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/employee.php",
      { id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addEditOwner = (owner) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/api/addEditOwner.php", owner, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteOwner = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/addEditOwner.php",
      { id, delete: true },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getOwners = () => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/owner.php",
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getOwner = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/owner.php",
      { id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addEditCustomer = (customer) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/api/addEditCustomer.php", customer, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteCustomer = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/addEditCustomer.php",
      { id, delete: true },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getCustomers = () => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/customer.php",
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getCustomer = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/customer.php",
      { id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addEditBuilding = (building) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/api/addEditBuilding.php", building, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteBuilding = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/addEditBuilding.php",
      { id, delete: true },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getBuildings = () => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/building.php",
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getBuilding = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/building.php",
      { id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addEditRoom = (room) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/api/addEditRoom.php", room, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteRoom = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/addEditRoom.php",
      { id, delete: true },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getRooms = () => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/room.php",
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getRoom = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/room.php",
      { id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addEditTransaction = (transaction) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/api/addEditTransaction.php", transaction, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getTransactions = (filters) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/api/transaction.php", filters, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const collectedTransaction = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/addEditTransaction.php",
      { id, transactionCollected: true },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addEditRent = (rent) => {
  return axios
    .post(process.env.REACT_APP_APIPATH + "/api/addEditRent.php", rent, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteRent = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/addEditCustomer.php",
      { id, delete: true },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getRents = () => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/rent.php",
      {},
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getRent = (id) => {
  return axios
    .post(
      process.env.REACT_APP_APIPATH + "/api/rent.php",
      { id },
      {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.usertoken}` },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const get_response_or_errors = (res) => {
  if (res.status == 401) {
    remove_session();
  } else if (res && res.status == 422) {
    return res.data.errors;
  } else if ((res && res.status == 400) || (res && res.status == 200)) {
    return {
      responseMessage: res.data.message,
      responseFlag: "danger",
    };
  } else {
    return {
      responseMessage: "Server Error. Please Contact Administration.",
      responseFlag: "danger",
    };
  }
};

export const add_session = (token) => {
  localStorage.setItem("usertoken", token);
};

export const set_user = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};
export const set_user_role = (userRole) => {
  localStorage.setItem("userRole", JSON.stringify(userRole));
};

export const remove_session = () => {
  localStorage.removeItem("usertoken");
  localStorage.removeItem("user");
  localStorage.removeItem("userRole");
};

export const check_response_status = (res) => {
  if (res.status == 401) {
    localStorage.removeItem("usertoken");
    window.location.reload();
  }
};

export const date_time_to_date_format = (datetime) => {
  const dateObject = new Date(datetime);
  return dateObject.toDateString();
};

export const is_logged_in = () => {
  return localStorage.usertoken;
};

export const current_user = () => {
  return localStorage.user ? JSON.parse(localStorage.user) : "";
};
export const user_role = () => {
  return localStorage.userRole ? JSON.parse(localStorage.userRole) : "";
};

export const is_valid_slug = (slug) => {
  const regexExp = /^[a-z0-9]+(?:-[a-z0-9]+)*$/g;
  return regexExp.test(slug);
};
